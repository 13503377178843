import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { useTranslation } from 'react-i18next';
import { BodyMediumBold, BodyMediumSemiBold } from '@src/components/styled/Typography';
import { ContainerRow } from '@src/components/styled';
import { ReactComponent as SuccessIconComponent } from '@icons/wolfkit-solid/checkmark-with-check-circle-solid.svg';
const StepTitle = styled(BodyMediumBold)(() => ({
    fontSize: 24,
    lineHeight: '30px',
    letterSpacing: '-0.456px',
}));
const StepContainerComponent = styled('div', { shouldForwardProp: prop => isPropValid(prop) })(props => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: props.theme.baseSize * 70,
    height: '100%',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    padding: props.padding ? props.padding : `${props.theme.spacing_sizes.xs * 5}px 0px 0px`,
    gap: props.containerGap,
}));
const StepForm = styled('form', { shouldForwardProp: prop => isPropValid(prop) })(props => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: props.theme.baseSize * 70,
    height: '100%',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    padding: props.padding ? props.padding : `${props.theme.spacing_sizes.xs * 5}px 0px 0px`,
    gap: props.containerGap,
}));
const StepTextContainer = styled(BodyMediumSemiBold, { shouldForwardProp: propName => propName !== 'margin' })(props => ({
    alignSelf: 'start',
    margin: props.margin,
    letterSpacing: '-0.176px',
}));
const TitleContainer = styled.div(() => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
}));
const ButtonsGroup = styled.div(props => ({
    display: 'flex',
    flexDirection: 'column',
    gap: props.buttonsGap,
    width: '100%',
    justifyContent: 'end',
    flex: props.buttonsFlex,
}));
const StepNumber = styled.span(props => ({
    color: props.theme.customColors.input.borderHover,
    lineHeight: '24px',
}));
const StepTextComponent = styled.span(() => ({
    lineHeight: '24px',
}));
const StepText = ({ stepNumber, text, margin = '0 0 24px 0', }) => (_jsxs(StepTextContainer, { margin: margin, children: [_jsx(StepNumber, { children: stepNumber }), ' ', _jsx(StepTextComponent, { children: text })] }));
const InnerElements = styled.div((props) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
    alignItems: props.contentAlign,
    justifyContent: props.contentJustify,
    flex: props.contentFlex,
}));
const StepContainer = ({ content, title = undefined, buttons = undefined, buttonsFlex = undefined, contentAlign = 'center', contentJustify = 'center', contentFlex = undefined, buttonsGap = 12, containerGap = undefined, containerPadding = undefined, }) => (_jsxs(StepContainerComponent, { containerGap: containerGap, padding: containerPadding, children: [_jsx(TitleContainer, { children: title || null }), _jsx(InnerElements, { contentAlign: contentAlign, contentJustify: contentJustify, contentFlex: contentFlex, children: content }), _jsx(ButtonsGroup, { buttonsGap: buttonsGap, buttonsFlex: buttonsFlex, children: buttons || null })] }));
const StepFormContainer = ({ content, title = undefined, buttons = undefined, buttonsFlex = undefined, onSubmit = undefined, contentAlign = 'center', contentJustify = 'center', contentFlex = undefined, buttonsGap = 12, containerGap = undefined, containerPadding = undefined, }) => (_jsxs(StepForm, { onSubmit: onSubmit, containerGap: containerGap, padding: containerPadding, children: [_jsx(TitleContainer, { children: title || null }), _jsx(InnerElements, { contentAlign: contentAlign, contentJustify: contentJustify, contentFlex: contentFlex, children: content }), _jsx(ButtonsGroup, { buttonsGap: buttonsGap, buttonsFlex: buttonsFlex, children: buttons || null })] }));
const DividerContainer = styled(ContainerRow)((props) => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
    alignItems: 'center',
    margin: `${props.theme.spacing_sizes.xm}px 0`,
}));
const DividerText = styled(BodyMediumSemiBold)(() => ({
    lineHeight: '24px',
    color: '#ABB4BB',
    textWrap: 'nowrap',
}));
const DividerBorder = styled.span(() => ({
    display: 'block',
    width: '100%',
    backgroundColor: '#ABB4BB',
    height: 1,
}));
const Divider = () => {
    const { t } = useTranslation();
    return (_jsxs(DividerContainer, { children: [_jsx(DividerBorder, {}), _jsx(DividerText, { children: t('overview.connect_exchange.connect_exchange.unavailable_options') }), _jsx(DividerBorder, {})] }));
};
const SuccessIcon = styled(SuccessIconComponent)(() => ({
    width: 70,
    height: 70,
}));
const ErrorIconComponent = styled.span(props => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 70,
    height: 70,
    background: '#FFF6F6',
    borderRadius: '50%',
    fontSize: 46,
    fontWeight: 600,
    color: props.theme.palette.error.main,
    pointerEvents: 'none',
    cursor: 'none',
}));
const ErrorIcon = () => _jsx(ErrorIconComponent, { role: 'img', children: "!" });
const IconContainer = styled.div(props => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 100,
    height: 100,
    marginBottom: props.theme.spacing_sizes.xs * 3.25,
}));
export { StepContainer, StepFormContainer, StepTitle, StepText, Divider, SuccessIcon, IconContainer, ErrorIcon, };
